import React, { Component } from "react";
import { Link } from "react-router-dom";

class Menu extends Component {
  render() {
    const { isHomePage } = this.props;
    return (
      <>
        <div className="container">
          <div className="menu">
            <div className="menu-left-side">
              <Link className="logo" to="/">
                <img
                  src="/intro/tania-sadurschi-logo.svg"
                  width="200"
                  height="100"
                  alt="logo"
                />
              </Link>
              {isHomePage && (
                <div>
                  <div className="menu-hey">Hey!</div>
                  <div className="menu-intro-phrase">
                    I'm{" "}
                    <span className="menu-name-yellow">Tania Sadurschi</span>,
                  </div>
                  <div className="menu-intro-phrase">a Product Designer</div>
                  <div className="menu-description">
                    Passionate about UI/UX Design and solving complex problems
                    that mobile and web apps are facing.
                  </div>
                  <a
                    href="https://www.linkedin.com/in/taniasadurschi/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="menu-linkedin-button"
                  >
                    <img src="/intro/linkedin-button.png" alt="linkedin" />
                  </a>
                </div>
              )}
            </div>
            {isHomePage && (
              <div className="portret-container">
                <img
                  className="portret"
                  src="/intro/portret-tania.jpg"
                  alt="portret"
                />
              </div>
            )}
          </div>
        </div>
        {isHomePage && (
          <div className="background-grey">
            <div className="container">
              <div className="company-logos">
                <img
                  src="/company-logos/sonar.svg"
                  loading="lazy"
                  alt="company-logo"
                />
                <img
                  src="/company-logos/producement.svg"
                  loading="lazy"
                  alt="company-logo"
                />
                <img
                  src="/company-logos/equilobe.svg"
                  loading="lazy"
                  alt="company-logo"
                />
                <img
                  src="/company-logos/ionos.svg"
                  loading="lazy"
                  alt="company-logo"
                />
                <img
                  src="/company-logos/nexibeo.svg"
                  loading="lazy"
                  alt="company-logo"
                />
                <img
                  src="/company-logos/company1.svg"
                  loading="lazy"
                  alt="company-logo"
                />
              </div>
            </div>
          </div>
        )}
        {isHomePage && (
          <div>
            <h1 className="projects-title">Projects</h1>
          </div>
        )}
      </>
    );
  }
}

export default Menu;
