import React from "react";
import { createPortal } from "react-dom";

const modalRoot = document.getElementById("modal");

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.element = document.createElement("div");
  }

  componentDidMount() {
    modalRoot.appendChild(this.element);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.element);
  }
  render() {
    const { toggleModal, imgUrl } = this.props;
    return createPortal(
      <div className="modal">
        <img loading="lazy" src={imgUrl} alt="" onClick={toggleModal} />
        <button onClick={toggleModal}>Close</button>
      </div>,
      this.element
    );
  }
}
export default Modal;
