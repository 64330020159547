import React, { Component } from "react";
import { Link } from "react-router-dom";
import Menu from "../components/menu";
import projects from "./all-projects.json";

const generateLink = (title) => {
  let baseUrl = "";
  if (!["Vitamin Box App", "Deliberate App"].includes(title)) {
    baseUrl = "/project";
  }
  return `${baseUrl}/${title.split(" ").join("-").toLowerCase()}`;
};

const Card = ({ project }) => {
  if (project.isHtml) {
    return (
      <a className="project" href={generateLink(project.title)}>
        <span
          style={{ backgroundColor: project.linkBgColor }}
          className="project-link-text"
        >
          {project.linkText}
          <img src="/other/external-link.svg" alt="link" />
        </span>
        <img
          loading="lazy"
          src={project.photos.url1}
          alt=""
          className="project-photo"
        />
        <div className="project-description">{project.title}</div>
      </a>
    );
  }

  if (project.isExternal) {
    return (
      <a
        href={project.href}
        target="_blank"
        rel="noopener noreferrer"
        className="project"
      >
        <span
          style={{ backgroundColor: project.linkBgColor }}
          className="project-link-text"
        >
          {project.linkText}
          <img src="/other/external-link.svg" alt="link" />
        </span>
        <img
          loading="lazy"
          src={project.photos.url1}
          alt=""
          className="project-photo"
        />
        <div className="project-description">{project.title}</div>
      </a>
    );
  }

  return (
    <Link className="project" to={generateLink(project.title)}>
      <span
        style={{ backgroundColor: project.linkBgColor }}
        className="project-link-text"
      >
        {project.linkText}
        <img src="/other/external-link.svg" alt="link" />
      </span>
      <img
        loading="lazy"
        src={project.photos.url1}
        alt=""
        className="project-photo"
      />
      <div className="project-description">{project.title}</div>
    </Link>
  );
};

class Homepage extends Component {
  render() {
    return (
      <div>
        <Menu isHomePage />
        <div className="container">
          <div className="projects">
            {projects.map((project, index) => (
              <Card project={project} key={index} />
            ))}
          </div>
        </div>
        <div className="delimiter"></div>
        <div className="about container">
          <h2>About</h2>
          <p>
            I am a Romanian expat living in the Netherlands. Having grown up in
            the mountains, I now find myself in a country known for its flat
            landscapes, boats, and bikes. I am grateful to live in a
            multicultural country where I can learn about different cultures and
            traditions.
          </p>
          <p>
            Currently, I work at PublicSonar, where I take part in an exciting
            events focused on innovation and collaboration. The photos on my
            website captures the happy moments when my team emerged victorious
            in the competition - which happened to be on my birthday.
            <br />
            When I am not working, I love to travel and immerse myself in new
            cultures. I relish hearing the stories of locals and fellow
            travelers. The second photo on my website captures a fond memory
            from my trip to Gran Canaria.
          </p>
          <p>
            In addition to travel, I also enjoy spending time with my
            colleagues. The last two photos on my website depict my recent
            business trips to Portugal and an indoor ski place, where I learned
            how to ski with my colleagues.
          </p>
          <p>Thank you for taking the time to get to know me.</p>
          <div className="about-photos">
            <img src="/about-photos/about-1.jpg" loading="lazy" alt="about" />
            <img src="/about-photos/about-2.jpg" loading="lazy" alt="about" />
            <img src="/about-photos/about-3.jpg" loading="lazy" alt="about" />
            <img src="/about-photos/about-4.jpg" loading="lazy" alt="about" />
          </div>
        </div>
      </div>
    );
  }
}

export default Homepage;
