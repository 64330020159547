import React, { Component, Suspense } from "react";
import Loading from "../components/loading";
import Menu from "../components/menu";

class ProjectPage extends Component {
  render() {
    const {
      toggleModal,
      match: {
        params: { project },
      },
    } = this.props;

    const Project = React.lazy(() => import(`../project/${project}`));

    return (
      <Suspense
        fallback={
          <div className="loading-container">
            <Loading />
          </div>
        }
      >
        <Menu />
        <div className="container">
          <Project toggleModal={toggleModal} />
        </div>
      </Suspense>
    );
  }
}

export default ProjectPage;
