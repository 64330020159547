import React, { Component } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Modal from "./components/modal";

import HomePage from "./pages/home";
import ProjectPage from "./pages/project";

class App extends Component {
  state = {
    showModal: false,
  };

  componentDidMount() {
    if (window.location.pathname.toLowerCase() === "/cv") {
      window.location.replace("/CV-Tania-Sadurschi.pdf");
    }
  }

  toggleModal = (imgUrl) => {
    this.setState({
      showModal: !this.state.showModal,
      imgUrl,
    });
  };

  render() {
    const { showModal, imgUrl } = this.state;

    return (
      <div>
        {showModal ? (
          <Modal toggleModal={this.toggleModal} imgUrl={imgUrl} />
        ) : null}
        <Router>
          <Switch>
            <Route
              path="/project/:project"
              render={(props) => (
                <ProjectPage toggleModal={this.toggleModal} {...props} />
              )}
            />

            <Route path="/cv" render={(props) => <h1>Loading...</h1>} />

            <Route exact path="/" render={(props) => <HomePage {...props} />} />

            <Route path="*">
              <div style={{ textAlign: "center", margin: "50px" }}>
                404 Not Found{" "}
                <span role="img" aria-label="amazed emoji">
                  😲
                </span>{" "}
                - There is no page for the current URL!
                <p>
                  Go to: <Link to="/">Home page</Link>
                </p>
              </div>
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
